import React from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';

import * as ROLES from "../../../constants/roles";
import * as ROUTES from "../../../constants/routes";
import * as TRANSITION from "../../../constants/transitions";

import { SignOutButton } from '../user';

import { Nav, Button } from 'react-bootstrap';
import { DrawerNavigation } from 'react-bootstrap-drawer';

import {PrefGlobal} from "../preferences"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGem } from "@fortawesome/free-regular-svg-icons";
import { motion } from "framer-motion";

const CustomNavigation = ({ authUser, preferences }) =>
  authUser ? (
  <motion.div initial="initial" animate="in" exit="out" transition={TRANSITION.NAV} variants={TRANSITION.NAVVARIANTS} style={{backgroundColor:preferences, color:'white'}} className="nav-side" >
    <NavigationAuth authUser={authUser} />
  </motion.div>
    ) : (
  <motion.div initial="initial" animate="in" exit="out" transition={TRANSITION.NAV} variants={TRANSITION.NAVVARIANTS} style={{backgroundColor:preferences}} className="nav-side" >
    <NavigationNonAuth />
  </motion.div>
);

export const NavigationAuth = ({authUser, props}) => {
	return (
		<>
			<NavLink activeClassName="active" className="nav-link navbar-brand" to={ROUTES.PUBLIC}><FontAwesomeIcon className="mr-3" icon={faGem} fixedWidth />{PrefGlobal.name}</NavLink>

			<DrawerNavigation>
        <NavLink activeClassName="active" className="nav-link" to={ROUTES.HOME}>Home</NavLink>
        <NavLink activeClassName="active" className="nav-link" to={ROUTES.PROJECTS}>Projects</NavLink>
        <NavLink activeClassName="active" className="nav-link" to={ROUTES.ASSETS}>Assets</NavLink>
        <NavLink activeClassName="active" className="nav-link" to={ROUTES.RESOURCES}>Resources</NavLink>
        <NavLink activeClassName="active" className="nav-link" to={ROUTES.CLIENTS}>Clients</NavLink>
        <NavLink activeClassName="active" className="nav-link" to={ROUTES.BILLINGS}>Billing</NavLink>
          {!!authUser.roles[ROLES.ADMIN] && (
            <NavLink activeClassName="active" className="nav-link" to={ROUTES.ADMIN}>Admin</NavLink>
          )}
			</DrawerNavigation>
      <Nav className="justify-content-start">
              <NavLink activeClassName="active" className="nav-link" to={ROUTES.ACCOUNT}>{authUser.username}</NavLink>
              <SignOutButton />
      </Nav>
		</>
	);
};

export const NavigationNonAuth = ({authUser, props}) => {
	return (
		<>
			<NavLink activeClassName="active" className="nav-link navbar-brand" to={ROUTES.PUBLIC}><FontAwesomeIcon className="mr-3" icon={faGem} fixedWidth />{PrefGlobal.name}</NavLink>

			<DrawerNavigation>
          <NavLink activeClassName="active" className="nav-link" to={ROUTES.PUBLIC_CASES}>Cases</NavLink>
          <NavLink activeClassName="active" className="nav-link" to={ROUTES.PUBLIC_TEAM}>Team</NavLink>
          <NavLink activeClassName="active" className="nav-link" to={ROUTES.PUBLIC_CONTACT}>Contact</NavLink>
			</DrawerNavigation>
      <Nav className="justify-content-end">
            <NavLink activeClassName="active" to={ROUTES.SIGN_IN}>
              <Button variant="dark">Sign In</Button>
            </NavLink>
      </Nav>
		</>
	);
};

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
  preferences: state.preferencesState.primaryColor,
});

export default connect(mapStateToProps)(CustomNavigation);
