import React from 'react';
import lazyLoading from "../../../constants/lazyload";
import ProgressBar from "react-topbar-progress-indicator";


ProgressBar.config({
  barColors: {
    "0": "#000"
  },
  barThickness: 2
});

export const NavigationGlobal = lazyLoading(() => import("../navigation/global"), {
  fallback: <ProgressBar />
});

export const CustomDrawer = lazyLoading(() => import("../navigation/drawer"), {
  fallback: <ProgressBar />
});

export const HomePage = lazyLoading(() => import("../../pages/home"), {
  fallback: <ProgressBar />
});

export const ProjectsPage = lazyLoading(() => import("../../pages/projects"), {
  fallback: <ProgressBar />
});

export const ResourcesPage = lazyLoading(() => import("../../pages/resources"), {
  fallback: <ProgressBar />
});

export const AssetsPage = lazyLoading(() => import("../../pages/assets"), {
  fallback: <ProgressBar />
});

export const ClientsPage = lazyLoading(() => import("../../pages/clients"), {
  fallback: <ProgressBar />
});

export const BillingsPage = lazyLoading(() => import("../../pages/billings"), {
  fallback: <ProgressBar />
});

export const PublicPage = lazyLoading(() => import("../../pages/public"), {
  fallback: <ProgressBar />
});

export const PublicHomePage = lazyLoading(() => import("../../pages/public/home"), {
  fallback: <ProgressBar />
});

export const PublicCasePage = lazyLoading(() => import("../../pages/public/cases"), {
  fallback: <ProgressBar />
});

export const PublicTeamPage = lazyLoading(() => import("../../pages/public/team"), {
  fallback: <ProgressBar />
});

export const PublicContactPage = lazyLoading(() => import("../../pages/public/contact"), {
  fallback: <ProgressBar />
});

export const SignUpPage = lazyLoading(() => import("../user/signup"), {
  fallback: <ProgressBar />
});

export const SignInPage = lazyLoading(() => import("../user/signin"), {
  fallback: <ProgressBar />
});

export const PasswordForgetPage = lazyLoading(() => import("../user/pwrecovery"), {
  fallback: <ProgressBar />
});

export const AccountPage = lazyLoading(() => import("../../pages/user/account"), {
  fallback: <ProgressBar />
});

export const Admin = lazyLoading(() => import("../../pages/admin"), {
  fallback: <ProgressBar />
});
