import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../firebase';

import * as ROUTES from '../../../constants/routes';
import * as TRANSITION from '../../../constants/transitions'
import BackButton from '../../../constants/routes';

import { Container, Form, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGem } from "@fortawesome/free-regular-svg-icons";
import { motion } from 'framer-motion';

const PasswordForgetPage = () => (
<Container fluid className="cont_wrapper mt-3">
  <Row className="justify-content-center">
    <Col sm={8} className="text-center" >
      <motion.div style={TRANSITION.STYLE} initial="initial" animate="in" exit="out" transition={TRANSITION.PAGE} variants={TRANSITION.VARIANTS} positionTransition={{ type: "spring", stiffness: 80 }}>
        <motion.div variants={TRANSITION.ITEM} className="text-center mt-6 mb-4">
          <Link to={ROUTES.PUBLIC}><FontAwesomeIcon icon={faGem} size="4x" /></Link>
        </motion.div>
        <motion.div variants={TRANSITION.ITEM} className="text-center mt-4 mb-4">
          <h3>Reset your password</h3>
        </motion.div>
        <div className="auth-wrapper">
          <div className="auth-inner">
            <motion.div variants={TRANSITION.ITEM}>
              <PasswordForgetForm />
            </motion.div>
          </div>
        </div>
        <motion.div variants={TRANSITION.ITEM}>
          <BackButton/>
        </motion.div>
      </motion.div>
    </Col>
  </Row>
</Container>
);
const INITIAL_STATE = {
  email: '',
  error: null,
};
class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, error } = this.state;
    const isInvalid = email === '';
    return (
      <Form className="transition_item" onSubmit={this.onSubmit}>
        <motion.div variants={TRANSITION.ITEM} className="mt-3">
          <Form.Group>
            <Form.Label><strong>Email adress</strong></Form.Label>
                <Form.Control name="email" value={this.state.email} onChange={this.onChange} type="email" placeholder="Enter your email address"/>
            </Form.Group>
          </motion.div>
          <motion.div variants={TRANSITION.ITEM} className="mt-3">
            <Form.Text className="text-muted">
              Enter your user account's verified email address and we will send you a password reset link.
            </Form.Text>
          </motion.div>
          <motion.div variants={TRANSITION.ITEM} className="mt-3">
            <Form.Group>
                <Button disabled={isInvalid} className="btn btn-dark" size="lg" block type="submit">
                  Send reset email
                </Button>
            </Form.Group>
          </motion.div>
        {error && <p>{error.message}</p>}
      </Form>
    );
  }
}
const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);
export default PasswordForgetPage;
const PasswordForgetForm = withFirebase(PasswordForgetFormBase);
export { PasswordForgetForm, PasswordForgetLink };
