import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();

    this.googleProvider = new app.auth.GoogleAuthProvider();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
  this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  doSendEmailVerification = () =>
  this.auth.currentUser.sendEmailVerification({
    url: config.url,
  });

  checkUserExist = (username) =>
  this.db.ref('users').orderByChild('username').equalTo(username).once('value', snapshot => {
      if (!snapshot.exists()) {
        console.log('No users found')
      }
      else {
        snapshot.forEach(child => {
          console.log('User found: '+child.val().username)
        });
      }
    });

  checkEmailExist = (email) =>
  this.db.ref('users').orderByChild('email').equalTo(email).once('value', snapshot => {
      if (!snapshot.exists()) {
        console.log('No emails found')
      }
      else {
        snapshot.forEach(child => {
          console.log('Email found: '+child.val().email)
        });
      }
    });

    // *** Merge Auth and DB User API *** //

    onAuthUserListener = (next, fallback) =>
      this.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          this.user(authUser.uid)
            .once('value')
            .then(snapshot => {
              const dbUser = snapshot.val();

              // default empty roles
              if (!dbUser.roles) {
                dbUser.roles = {};
              }

              // merge auth and db user
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                ...dbUser,
              };

              next(authUser);
            });
        } else {
          fallback();
        }
      });

  // *** User API ***
  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');

  // *** Project API ***
  project = uid => this.db.ref(`projects/${uid}`);
  projects = () => this.db.ref('projects');

  // *** Client API ***
  client = uid => this.db.ref(`clients/${uid}`);
  clients = () => this.db.ref('clients');

  // *** Accounts API ***
  account = uid => this.db.ref(`accounts/${uid}`);
  accounts = () => this.db.ref('accounts');

  // *** Preferences API ***
  preferences = () => this.db.ref('preferences');
  prefdefaultprimarycolor = () => this.db.ref('preferences/defaultPrimaryColor');

  // *** Storage API ***
  storage = () => this.storage.ref('uploads');
}


export default Firebase;
