import React from 'react';
import { withRouter } from "react-router";
import { Button } from 'react-bootstrap';

const BackButton = ({history, location}) => (<Button variant="outline-dark" className="backbutton mt-4 mb-4" onClick={() => window.history.back()}>&#60; Go back</Button>);

export default withRouter(BackButton);

export const SIGN_UP = '/signup';
export const SIGN_IN = '/login';
export const HOME = '/home';

export const PROJECTS = '/projects';
export const PROJECTS_PROJECT = '/projects/:id';

export const PUBLIC = '/';
export const PUBLIC_CASES = '/cases';
export const PUBLIC_CASE = '/cases/:id';
export const PUBLIC_TEAM = '/team';
export const PUBLIC_CONTACT = '/contact';

export const RESOURCES = '/resources';
export const RESOURCES_RESOURCE = '/resources/:id';

export const ASSETS = '/assets';
export const ASSETS_ASSET = '/assets/:id';

export const CLIENTS = '/clients';
export const CLIENTS_CLIENT = '/clients/:id';

export const BILLINGS = '/billings';
export const BILLINGS_BILL = '/billings/:id';

export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/recovery';
export const ADMIN = '/admin';
export const ADMIN_USER = '/admin/u/:id';
