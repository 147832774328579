const INITIAL_STATE = {
  primaryColor: '#f4f4f4',
  secondaryColor: null,
  language: 'ES_es',
};

const applySetPreferencePrimary = (state, action) => ({
  ...state,
  primaryColor: action.primaryColor,
});

const applySetPreferenceSecondary = (state, action) => ({
  ...state,
  secondaryColor: action.secondaryColor,
});

const applySetPreferenceLanguage = (state, action) => ({
  ...state,
  language: action.language,
});

function preferenceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'PREFERENCE_PRIMARY_SET': {
      return applySetPreferencePrimary(state, action);
    }
    case 'PREFERENCE_SECONDARY_SET': {
      return applySetPreferenceSecondary(state, action);
    }
    case 'PREFERENCE_LANGUAGE_SET': {
      return applySetPreferenceLanguage(state, action);
    }
    default:
      return state;
  }
}

export default preferenceReducer;
