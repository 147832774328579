import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../firebase';
import { PrefGlobal } from '../preferences';

import * as ROUTES from '../../../constants/routes';
import * as TRANSITION from '../../../constants/transitions'

import {PasswordForgetLink, SignUpLink} from './';

import { Container, Col, Row, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGem } from "@fortawesome/free-regular-svg-icons";
import { motion } from 'framer-motion';

const SignInPage = () => (
<motion.div style={TRANSITION.STYLE} initial="initial" animate="in" exit="out" transition={TRANSITION.PAGE} variants={TRANSITION.VARIANTS} positionTransition={{ type: "spring", stiffness: 80 }}>
<Container fluid className="cont_wrapper mt-3">
<Row className="justify-content-center">
  <Col sm={8} className="text-center" >
    <motion.div variants={TRANSITION.ITEM} className="text-center mt-6 mb-4">
      <Link to={ROUTES.PUBLIC}><FontAwesomeIcon icon={faGem} size="4x" /></Link>
    </motion.div>
      <motion.div variants={TRANSITION.ITEM} className="text-center mt-4 mb-4">
        <h3>Sign in to {PrefGlobal.name}</h3>
      </motion.div>
      <div className="auth-wrapper">
        <div className="auth-inner">
          <motion.div variants={TRANSITION.ITEM}>
            <SignInForm />
          </motion.div>
        </div>
      <div className="auth-inner">
        <motion.div variants={TRANSITION.ITEM}>
        <SignInGoogle />
        </motion.div>
      </div>
        <div className="auth-inner auth-extra mt-3">
          <motion.div variants={TRANSITION.ITEM} className="text-center">
            <SignUpLink />
          </motion.div>
        </div>
      </div>
  </Col>
</Row>
</Container>
    </motion.div>
);
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        this.props.firebase
          .user(socialAuthUser.user.uid)
          .update({
            email: socialAuthUser.user.email,
          })
          .then(() => {
            this.setState({ error: null });
            this.props.history.push(ROUTES.HOME);
          })
          .catch(error => {
            this.setState({ error });
          });
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Google</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInFormBase extends Component {
  constructor(props, state) {
    super(props);
    this.state = { ...INITIAL_STATE };
    state = {
      isLoading: false,
      show: false,
      shake: false
    }
  }

  onSubmit = event => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
        this.setState({ shake: false, isLoading: false, show: false });
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        this.setState({ error });
        this.setState({ shake: true, isLoading: false, show: false });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';
    const isLoading = this.state.isLoading;
    const shake = this.state.shake;

    return (
      <Form className={shake ? 'shake transition_item' : 'transition_item'} onAnimationEnd={() => this.setState({ shake: false })} onSubmit={this.onSubmit}>
      <motion.div variants={TRANSITION.ITEM} className="mt-3">
        <Form.Group>
          <Form.Label><strong>Username or email adress</strong></Form.Label>
              <Form.Control name="email" value={email} onChange={this.onChange} type="text" placeholder="Email Address"/>
          </Form.Group>
        </motion.div>
        <motion.div variants={TRANSITION.ITEM} className="mt-3">
          <Form.Group>
            <Form.Label><strong>Password</strong></Form.Label><div className="text-small"><small><strong><PasswordForgetLink /></strong></small></div>
              <Form.Control name="password" value={password} onChange={this.onChange} type="password" placeholder="Password" />
          </Form.Group>
        </motion.div>
        <motion.div variants={TRANSITION.ITEM} className="mt-3">
          <Form.Group>
              <Button disabled={isInvalid|isLoading} className='btn btn-dark' size="lg" block type="submit">
                {isLoading ? 'Login you in…' : 'Login'}
              </Button>
          </Form.Group>
        </motion.div>
        {error && <p>{error.message}</p>}
      </Form>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

export default SignInPage;

export { SignInForm, SignInGoogle };
