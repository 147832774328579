import React from 'react';

import { withFirebase } from '../firebase';

import { Button } from 'react-bootstrap';

const SignOutButton = ({firebase}) => (
  <Button variant="outline-dark" onClick={firebase.doSignOut}>
    Sign Out
  </Button>
);

export default withFirebase(SignOutButton);
