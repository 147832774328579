import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import * as TRANSITION from '../../../../../constants/transitions'

import { withFirebase } from '../../../firebase';

import { motion } from 'framer-motion';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.props.onSetAuthUser(
        JSON.parse(localStorage.getItem('authUser')),
      );
    }

    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          localStorage.setItem('authUser', JSON.stringify(authUser));
          this.props.onSetAuthUser(authUser);
        },
        () => {
          localStorage.removeItem('authUser');
          this.props.onSetAuthUser(null);
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return         <motion.div style={TRANSITION.STYLE} initial="initial" animate="in" exit="out" transition={TRANSITION.PAGE} variants={TRANSITION.VARIANTS} positionTransition={{ type: "spring", stiffness: 80 }}><Component {...this.props} /></motion.div>;
    }
  }

  const mapDispatchToProps = dispatch => ({
    onSetAuthUser: authUser =>
      dispatch({ type: 'AUTH_USER_SET', authUser }),
  });

  return compose(
    withFirebase,
    connect(
      null,
      mapDispatchToProps,
    ),
  )(WithAuthentication);
};

export default withAuthentication;
