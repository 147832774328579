import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { withAuthentication } from '../core/user';

import * as ROUTES from "../../constants/routes";
import * as ROUTE from "../core/preferences/routes";

import { Col, Container, Row} from 'react-bootstrap';

import { AnimatePresence } from "framer-motion";
import CustomDrawer from '../core/navigation/drawer';

const App = () => (
  <div className="App">
    <Container fluid>
  <Row className="flex-xl-nowrap">
        <Router>
          <Col xs={ 12 } md={ 2 } lg={ 2 } className="sidemenu">
            <AnimatePresence exitBeforeEnter initial={false}>
                <Route path="/*" render={ ( props ) => ( props.location.pathname === "/login" || props.location.pathname === "/recovery" ? null : <CustomDrawer /> ) }/>
            </AnimatePresence>
          </Col>
          <Col xs={ 12 } md={ 9 } lg={ 10 } className="mainwrapper">
            <Route render={({ location }) => (
                <AnimatePresence exitBeforeEnter initial={true} >
                  <Switch location={location} key={location.pathname}>
                    <Route exact path={ROUTES.SIGN_UP} component={ROUTE.SignUpPage} />
                    <Route exact path={ROUTES.SIGN_IN} component={ROUTE.SignInPage} />
                    <Route exact path={ROUTES.PASSWORD_FORGET} component={ROUTE.PasswordForgetPage}/>
                    <Route exact path={ROUTES.HOME} component={ROUTE.HomePage} />
                    <Route exact path={ROUTES.PUBLIC} component={ROUTE.PublicPage} />
                    <Route exact path={ROUTES.PUBLIC_CASES} component={ROUTE.PublicCasePage} />
                    <Route exact={false} path={ROUTES.PUBLIC_CASE} component={ROUTE.PublicCasePage} />
                    <Route exact path={ROUTES.PUBLIC_TEAM} component={ROUTE.PublicTeamPage} />
                    <Route exact path={ROUTES.PUBLIC_CONTACT} component={ROUTE.PublicContactPage} />
                    <Route exact path={ROUTES.PROJECTS} component={ROUTE.ProjectsPage} />
                    <Route exact={false} path={ROUTES.PROJECTS_PROJECT} component={ROUTE.ProjectsPage} />
                    <Route exact path={ROUTES.RESOURCES} component={ROUTE.ResourcesPage} />
                    <Route exact path={ROUTES.ASSETS} component={ROUTE.AssetsPage} />
                    <Route exact path={ROUTES.CLIENTS} component={ROUTE.ClientsPage} />
                    <Route exact path={ROUTES.BILLINGS} component={ROUTE.BillingsPage} />
                    <Route exact path={ROUTES.ACCOUNT} component={ROUTE.AccountPage} />
                    <Route exact path={ROUTES.ADMIN} component={ROUTE.Admin} />
                    <Route path={ROUTES.ADMIN_USER} component={ROUTE.Admin} />
                  </Switch>

                </AnimatePresence>
              )}
            />
        </Col>
        </Router>
              </Row>
  </Container>
      </div>
    );

export default withAuthentication(App);
