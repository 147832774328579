let easing = [0.175, 0.85, 0.42, 0.96];

const PageTransition = {
  ease: "easeInOut",
  type: "spring",
  stiffness: 80,
  transition: { delay: 0.5, staggerChildren: 1, ease: easing   }
};

const PageVariants = {
  initial: {
    opacity:0,
    transition: {
      staggerChildren: 0.2
    }
  },
  in: {
    opacity:1,
    transition: {
      staggerChildren: 0.2
    }
  },
  out: {
    opacity:0,
    transition: {
      staggerChildren: 0.2,
      delay:0.2
    }
  }
};
const NavTransition = {
  ease: "easeInOut",
  type: "inertia",
  damping: 100,
  stiffness: 100,
  transition: { delay: 0.2, staggerChildren: 1, ease: easing   }
};

const NavVariants = {
  initial: {
    opacity: 1,
    y:"-10vh",
    transition: { delay: 0.2, staggerChildren: 0.1 }
  },
  in: {
    opacity: 1,
    y:"0",
    transition: { delay: 0.2, staggerChildren: 0.1 }
  },
  out: {
    opacity: 0,
    y:"-10vh",
    transition: { delay: 0.2, staggerChildren: 0.1 }
  }
};
const PageStyle = {
  position: ""
}

const Item = {
  ease: "easeInOut",
  type: "spring",
  stiffness: 80,
  transition: { delay: 0.1, ease: easing   },
  initial: {
    opacity: 0,
    transition: { delay: 0.1, ease: easing  },
  },
  out: { opacity: 0, delay:0.1 },
  in: {
    opacity: 1,
    transition: { delay: 0.1, ease: easing   },
  }
}
const List = {
  ease: "easeInOut",
  transition: { delay: 1, staggerChildren: 1, ease: easing, duration: 1  }
};
const ListVariants = {
  initial: {
    opacity:0,
    duration: 1,
  },
  in: {
    opacity:1,
    duration: 1,
  },
  out: {
    opacity:0,
    transition: {
      staggerChildren: 1
    }
  }
};
const ListItem = {
  ease: "easeInOut",
  transition: { delay: 0.5, ease: easing, duration: 1   },
  initial: {
    opacity: 0,
    transition: { delay: 0.5, ease: easing, duration: 1   },
  },
  out: { opacity: 0 },
  in: {
    opacity: 1,
    transition: { delay: 0.5, ease: easing, duration: 1  },
  }
}

export const VARIANTS = PageVariants;
export const PAGE = PageTransition;
export const STYLE = PageStyle;
export const ITEM = Item;
export const LIST = List;
export const LISTVARIANTS = ListVariants;
export const LISTITEM = ListItem;
export const NAVVARIANTS = NavVariants;
export const NAV = NavTransition;
