const INITIAL_STATE = {
  clients: null,
  clientNew: null,
  limit: 6,
};

const applySetClients = (state, action) => ({
  ...state,
  clients: action.clients,
});

const applySetClient = (state, action) => ({
  ...state,
  clients: {
    ...state.clients,
    [action.uid]: action.client,
    [action.clientSlug]: action.client,
  },
});

const applySetNewClient = (state, action) => ({
  ...state,
  clientNew: action.clientNew,
});

const applySetClientsLimit = (state, action) => ({
  ...state,
  limit: action.limit,
});

function clientReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CLIENTS_SET': {
      return applySetClients(state, action);
    }
    case 'CLIENT_SET': {
      return applySetClient(state, action);
    }
    case 'CLIENT_NEW': {
      return applySetNewClient(state, action);
    }
    case 'CLIENTS_LIMIT_SET': {
      return applySetClientsLimit(state, action);
    }
    default:
      return state;
  }
}

export default clientReducer;
