import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import * as TRANSITION from '../../../../../constants/transitions'

import { withFirebase } from '../../../firebase';

import { motion } from 'framer-motion';

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

    const withEmailVerification = Component => {
      class WithEmailVerification extends React.Component {
        constructor(props) {
          super(props);

          this.state = { isSent: false };
        }

        onSendEmailVerification = () => {
          this.props.firebase
            .doSendEmailVerification()
            .then(() => this.setState({ isSent: true }));
        };

        render() {
          return needsEmailVerification(this.props.authUser) ? (
            <div>
              {this.state.isSent ? (
                <p>
                  E-Mail confirmation sent: Check you E-Mails (Spam folder
                  included) for a confirmation E-Mail. Refresh this page
                  once you confirmed your E-Mail.
                </p>
              ) : (
                <p>
                  Verify your E-Mail: Check you E-Mails (Spam folder
                  included) for a confirmation E-Mail or send another
                  confirmation E-Mail.
                </p>
              )}

              <button
                type="button"
                onClick={this.onSendEmailVerification}
                disabled={this.state.isSent}
              >
                Send confirmation E-Mail
              </button>
            </div>
          ) : (
            <motion.div style={TRANSITION.STYLE} initial="initial" animate="in" exit="out" transition={TRANSITION.PAGE} variants={TRANSITION.VARIANTS} positionTransition={{ type: "spring", stiffness: 80 }}><Component {...this.props} /></motion.div>
          );
        }
      }

      const mapStateToProps = state => ({
        authUser: state.sessionState.authUser,
      });

      return compose(
        withFirebase,
        connect(mapStateToProps),
      )(WithEmailVerification);
    };

    export default withEmailVerification;
