import React, { Component } from 'react';

import { withFirebase } from '../firebase';

import * as TRANSITION from '../../../constants/transitions'

import { Form, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};
class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    const { passwordOne } = this.state;
    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { passwordOne, passwordTwo, error } = this.state;
    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';
    return (
      <Form className="transition_item"onSubmit={this.onSubmit}>
        <motion.div variants={TRANSITION.ITEM}>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control name="passwordOne" value={passwordOne} onChange={this.onChange} type="password" placeholder="New Password" />
        </Form.Group>
        </motion.div>
        <motion.div variants={TRANSITION.ITEM}>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control name="passwordTwo" value={passwordTwo} onChange={this.onChange} type="password" placeholder="Confirm new password" />
        </Form.Group>
        </motion.div>
        <motion.div variants={TRANSITION.ITEM}>
        <Button variant="dark" type="submit" disabled={isInvalid}>
          Reset my password
        </Button>
        </motion.div>
          {error && <p>{error.message}</p>}
        </Form>
    );
  }
}
export default withFirebase(PasswordChangeForm);
