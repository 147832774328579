const INITIAL_STATE = {
  projects: null,
  projectNew: null,
  limit: 6,
};

const applySetProjects = (state, action) => ({
  ...state,
  projects: action.projects,
});

const applySetProject = (state, action) => ({
  ...state,
  projects: {
    ...state.projects,
    [action.uid]: action.project,
    [action.projectSlug]: action.project,
  },
});

const applySetNewProject = (state, action) => ({
  ...state,
  projectNew: action.projectNew,
});

const applySetProjectsLimit = (state, action) => ({
  ...state,
  limit: action.limit,
});

function projectReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'PROJECTS_SET': {
      return applySetProjects(state, action);
    }
    case 'PROJECT_SET': {
      return applySetProject(state, action);
    }
    case 'PROJECT_NEW': {
      return applySetNewProject(state, action);
    }
    case 'PROJECTS_LIMIT_SET': {
      return applySetProjectsLimit(state, action);
    }
    default:
      return state;
  }
}

export default projectReducer;
