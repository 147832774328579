import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { withFirebase } from '../firebase';
import { PrefGlobal } from '../preferences';

import * as ROUTES from '../../../constants/routes';
import * as ROLES from '../../../constants/roles';
import * as TRANSITION from '../../../constants/transitions'

import { Container, Form, Button, Col, Row, InputGroup } from 'react-bootstrap';
import { motion } from 'framer-motion';



const SignUpPage = () => (
        <motion.div style={TRANSITION.STYLE} initial="initial" animate="in" exit="out" transition={TRANSITION.PAGE} variants={TRANSITION.VARIANTS} positionTransition={{ type: "spring", stiffness: 80 }}>
  <Container fluid className="cont_wrapper mt-3">
  <Row className="justify-content-center">
    <Col sm={8} className="text-center" >
        <motion.div variants={TRANSITION.ITEM} className="text-center mt-4 mb-4">
          <h3>Create your account at {PrefGlobal.name}</h3>
        </motion.div>
        <div className="auth-wrapper">
          <div className="auth-start">
              <SignUpForm />
          </div>
        </div>
    </Col>
  </Row>
</Container>
      </motion.div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne, isAdmin } = this.state;
    const roles = {};

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
            roles,
          });
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });

  };

  onChangeUser = event => {
    const { username } = this.state;
    this.setState({ [event.target.name]: event.target.value });
    this.props.firebase.checkUserExist(event.target.value);
    console.log(this.props.firebase.checkUserExist(username));
  };

  onChangeEmail = event => {
    const { email } = this.state;
    this.setState({ [event.target.name]: event.target.value });
    this.props.firebase.checkEmailExist(event.target.value);
    console.log(this.props.firebase.checkEmailExist(email));
  };

  onChangeCheckbox = event => {
  this.setState({ [event.target.name]: event.target.checked });
};

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      isAdmin,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
        <Form className="transition_item" onSubmit={this.onSubmit}>
        <motion.div variants={TRANSITION.ITEM} className="mb-3">
          <Form.Group>
            <Form.Label>Username</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" type="text"  name="username" value={username} onChange={this.onChangeUser}/>
            </InputGroup>
          </Form.Group>
          </motion.div>
          <motion.div variants={TRANSITION.ITEM} className="mb-3">
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control name="email" value={email} onChange={this.onChangeEmail} type="email" placeholder="name@example.com" />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>
          </motion.div>
          <motion.div variants={TRANSITION.ITEM} className="mb-3">
            <Form.Group>
              <Form.Label>Password</Form.Label>
                <Form.Control name="passwordOne" value={passwordOne} onChange={this.onChange} type="password" placeholder="Password" />
            </Form.Group>
          </motion.div>
          <motion.div variants={TRANSITION.ITEM} className="mb-3">
            <Form.Text className="text-muted">
              Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter. <a href="https://help.github.com/articles/creating-a-strong-password" aria-label="Learn more about strong passwords">Learn more</a>.
            </Form.Text>
          </motion.div>
          <motion.div variants={TRANSITION.ITEM} className="mb-3">
            <Form.Group>
              <Form.Label>Repeat Password</Form.Label>
                <Form.Control name="passwordTwo" value={passwordTwo} onChange={this.onChange} type="password" placeholder="Repeat Password" />
            </Form.Group>
          </motion.div>
          <motion.div variants={TRANSITION.ITEM} className="mb-3">
            <Form.Group>
              <p>Admin</p>
              <Form.Control name="isAdmin" checked={isAdmin} onChange={this.onChangeCheckbox} type="checkbox" label="Are you admin?" />
            </Form.Group>
          </motion.div>
          <motion.div variants={TRANSITION.ITEM} className="mt-3">
            <Form.Group>
                <Button disabled={isInvalid} className="btn btn-dark" size="lg" block type="submit">
                  Create account
                </Button>
            </Form.Group>
          </motion.div>
          <motion.div variants={TRANSITION.ITEM} className="my-3">
            <small>
              By creating an account, you agree to the
              <Link to="" target="_blank"> Terms of Service</Link>.
              For more information about {PrefGlobal.name} privacy practices, see the
              <Link to="" target="_blank"> {PrefGlobal.name}'s Privacy Statement</Link>.
              We'll occasionally send you account-related emails.
            </small>
          </motion.div>
            {error && <p>{error.message}</p>}
        </Form>
    );
  }
}

const SignUpLink = () => (
  <p className="auth-extra-text">
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default SignUpPage;

export { SignUpForm, SignUpLink };
