import { combineReducers } from 'redux';
import sessionReducer from './session';
import userReducer from './user';
import projectReducer from './project';
import clientReducer from './client';
import preferenceReducer from './preferences';

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  userState: userReducer,
  projectState: projectReducer,
  clientState: clientReducer,
  preferencesState: preferenceReducer,
});

export default rootReducer;
